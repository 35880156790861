/**
 * Application entry point
 */

// Load application styles
import 'styles/index.scss';

// Load application dependencies (jQuery and so on...)
import _ from 'lodash';
import $ from 'jquery';

// ================================
// START YOUR APP HERE
// ================================




let body = $('.content');
let burguer = $('.burguer');
let cross = $('.cross');
let menu = $('.nav');
let menumovil = $('.nav_movil');
let fondo = $('.fondo');
let vid = $('.video1');
let play = $('.play');
let pause = $('.pause');
console.log(body.innerWidth());



//window.onbeforeunload = function () {
//    window.scrollTo(0, 0);
//  }
//
//  $(document).scroll(function() {
//    if($(window).scrollTop() > 50){
//
//        fondo.addClass("opened");
//
//    }else if($(window).scrollTop() < 50){
//
//        fondo.removeClass("opened");
//
//    }
//});
//






window.reproducir = function() {
    vid.get(0).play();
};

window.pausar = function() {
    vid.get(0).pause();
}; 
    //
    //play.on("click", function playVid() {
    //    vid.play();
    //});
    //pause.on("click", function playVid() {
    //    vid.pause();
    //});

burguer.on("click", () => {
    burguer.toggleClass("closed");
    cross.toggleClass("opened");
    menu.toggleClass("opened");
    menumovil.toggleClass("opened");
    fondo.toggleClass("opened");
});

cross.on("click", () => {
    burguer.toggleClass("closed");
    cross.toggleClass("opened");
    menu.toggleClass("opened");
    menumovil.toggleClass("opened");
    fondo.toggleClass("opened");
});

$(document).ready(function(){
   $('input#input').on("keyup",function(){
        let valor = $(this).val();
        $('div.pruebas p').html(valor);
   });
});

$( window ).on( "load", function() {
    mainNav();
    $(window).scroll(function() {
        mainNav();
    });
    function mainNav() {
        var top = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        if (top > 250) fondo.stop().animate({
            "top": '0',
            "opacity": '1'
            
            
        }, '5', 'linear' );
        else fondo.stop().animate({
            "opacity": '0',
            "top": '-75'
        }, 5 );

	}
     });




$(function(){
    $('.img1').hover(function()
    {
        $('.img1').attr('src','assets/img/alignment/grid_1_1.jpg')
    },
    function()
    {
        $('.img1').attr('src','assets/img/alignment/grid_1.jpg')
    }
    )
}
)

$(function(){
    $('.img2').hover(function()
    {
        $('.img2').attr('src','assets/img/alignment/grid_2_1.jpg')
    },
    function()
    {
        $('.img2').attr('src','assets/img/alignment/grid_2.jpg')
    }
    )
}
)

$(function(){
    $('.img3').hover(function()
    {
        $('.img3').attr('src','assets/img/alignment/grid_3_1.jpg')
    },
    function()
    {
        $('.img3').attr('src','assets/img/alignment/grid_3.jpg')
    }
    )
}
)

$(function(){
    $('.img4').hover(function()
    {
        $('.img4').attr('src','assets/img/alignment/grid_4_1.jpg')
    },
    function()
    {
        $('.img4').attr('src','assets/img/alignment/grid_4.jpg')
    }
    )
}
)

$(function(){
    $('.img5').hover(function()
    {
        $('.img5').attr('src','assets/img/alignment/grid_5_1.jpg')
    },
    function()
    {
        $('.img5').attr('src','assets/img/alignment/grid_5.jpg')
    }
    )
}
)

$(function(){
    $('.img6').hover(function()
    {
        $('.img6').attr('src','assets/img/alignment/grid_6_1.jpg')
    },
    function()
    {
        $('.img6').attr('src','assets/img/alignment/grid_6.jpg')
    }
    )
}
)

$(function(){
    $('.img7').hover(function()
    {
        $('.img7').attr('src','assets/img/alignment/grid_7_1.jpg')
    },
    function()
    {
        $('.img7').attr('src','assets/img/alignment/grid_7.jpg')
    }
    )
}
)

$(function(){
    $('.img8').hover(function()
    {
        $('.img8').attr('src','assets/img/alignment/grid_8_1.jpg')
    },
    function()
    {
        $('.img8').attr('src','assets/img/alignment/grid_8.jpg')
    }
    )
}
)

$(function(){
    $('.img9').hover(function()
    {
        $('.img9').attr('src','assets/img/alignment/grid_9_1.jpg')
    },
    function()
    {
        $('.img9').attr('src','assets/img/alignment/grid_9.jpg')
    }
    )
}
)

$(function(){
    $('.img10').hover(function()
    {
        $('.img10').attr('src','assets/img/alignment/grid_10_1.jpg')
    },
    function()
    {
        $('.img10').attr('src','assets/img/alignment/grid_10.jpg')
    }
    )
}
)

$(function(){
    $('.img11').hover(function()
    {
        $('.img11').attr('src','assets/img/alignment/grid_11_1.jpg')
    },
    function()
    {
        $('.img11').attr('src','assets/img/alignment/grid_11.jpg')
    }
    )
}
)

$(function(){
    $('.img12').hover(function()
    {
        $('.img12').attr('src','assets/img/alignment/grid_12_1.jpg')
    },
    function()
    {
        $('.img12').attr('src','assets/img/alignment/grid_12.jpg')
    }
    )
}
)




$(function(){
    $('.icon2').hover(function()
    {
        $('.icon2').attr('src','assets/img/icons/cambio-50.svg')
    },
    function()
    {
        $('.icon2').attr('src','assets/img/icons/icon-26.svg')
    }
    )
}
)

$(function(){
    $('.icon3').hover(function()
    {
        $('.icon3').attr('src','assets/img/icons/cambio-51.svg')
    },
    function()
    {
        $('.icon3').attr('src','assets/img/icons/icon-27.svg')
    }
    )
}
)

$(function(){
    $('.icon4').hover(function()
    {
        $('.icon4').attr('src','assets/img/icons/cambio-52.svg')
    },
    function()
    {
        $('.icon4').attr('src','assets/img/icons/icon-28.svg')
    }
    )
}
)

$(function(){
    $('.icon5').hover(function()
    {
        $('.icon5').attr('src','assets/img/icons/cambio-53.svg')
    },
    function()
    {
        $('.icon5').attr('src','assets/img/icons/icon-29.svg')
    }
    )
}
)

$(function(){
    $('.icon6').hover(function()
    {
        $('.icon6').attr('src','assets/img/icons/cambio-54.svg')
    },
    function()
    {
        $('.icon6').attr('src','assets/img/icons/icon-30.svg')
    }
    )
}
)

$(function(){
    $('.icon7').hover(function()
    {
        $('.icon7').attr('src','assets/img/icons/cambio-55.svg')
    },
    function()
    {
        $('.icon7').attr('src','assets/img/icons/icon-31.svg')
    }
    )
}
)

$(function(){
    $('.icon8').hover(function()
    {
        $('.icon8').attr('src','assets/img/icons/cambio-56.svg')
    },
    function()
    {
        $('.icon8').attr('src','assets/img/icons/icon-32.svg')
    }
    )
}
)


$(function(){
    $('.icon9').hover(function()
    {
        $('.icon9').attr('src','assets/img/icons/cambio-57.svg')
    },
    function()
    {
        $('.icon9').attr('src','assets/img/icons/icon-33.svg')
    }
    )
}
)


$(function(){
    $('.icon10').hover(function()
    {
        $('.icon10').attr('src','assets/img/icons/cambio-58.svg')
    },
    function()
    {
        $('.icon10').attr('src','assets/img/icons/icon-34.svg')
    }
    )
}
)


$(function(){
    $('.icon11').hover(function()
    {
        $('.icon11').attr('src','assets/img/icons/cambio-59.svg')
    },
    function()
    {
        $('.icon11').attr('src','assets/img/icons/icon-35.svg')
    }
    )
}
)


$(function(){
    $('.icon12').hover(function()
    {
        $('.icon12').attr('src','assets/img/icons/cambio-60.svg')
    },
    function()
    {
        $('.icon12').attr('src','assets/img/icons/icon-36.svg')
    }
    )
}
)


$(function(){
    $('.icon13').hover(function()
    {
        $('.icon13').attr('src','assets/img/icons/cambio-61.svg')
    },
    function()
    {
        $('.icon13').attr('src','assets/img/icons/icon-37.svg')
    }
    )
}
)


$(function(){
    $('.icon14').hover(function()
    {
        $('.icon14').attr('src','assets/img/icons/cambio-62.svg')
    },
    function()
    {
        $('.icon14').attr('src','assets/img/icons/icon-38.svg')
    }
    )
}
)


$(function(){
    $('.icon15').hover(function()
    {
        $('.icon15').attr('src','assets/img/icons/cambio-63.svg')
    },
    function()
    {
        $('.icon15').attr('src','assets/img/icons/icon-39.svg')
    }
    )
}
)


$(function(){
    $('.icon16').hover(function()
    {
        $('.icon16').attr('src','assets/img/icons/cambio-64.svg')
    },
    function()
    {
        $('.icon16').attr('src','assets/img/icons/icon-40.svg')
    }
    )
}
)


$(function(){
    $('.icon17').hover(function()
    {
        $('.icon17').attr('src','assets/img/icons/cambio-65.svg')
    },
    function()
    {
        $('.icon17').attr('src','assets/img/icons/icon-41.svg')
    }
    )
}
)


$(function(){
    $('.icon18').hover(function()
    {
        $('.icon18').attr('src','assets/img/icons/cambio-66.svg')
    },
    function()
    {
        $('.icon18').attr('src','assets/img/icons/icon-42.svg')
    }
    )
}
)

$(function(){
    $('.icon19').hover(function()
    {
        $('.icon19').attr('src','assets/img/icons/cambio-67.svg')
    },
    function()
    {
        $('.icon19').attr('src','assets/img/icons/icon-43.svg')
    }
    )
}
)


$(function(){
    $('.icon20').hover(function()
    {
        $('.icon20').attr('src','assets/img/icons/cambio-68.svg')
    },
    function()
    {
        $('.icon20').attr('src','assets/img/icons/icon-44.svg')
    }
    )
}
)


$(function(){
    $('.icon21').hover(function()
    {
        $('.icon21').attr('src','assets/img/icons/cambio-69.svg')
    },
    function()
    {
        $('.icon21').attr('src','assets/img/icons/icon-45.svg')
    }
    )
}
)

$(function(){
    $('.icon22').hover(function()
    {
        $('.icon22').attr('src','assets/img/icons/cambio-70.svg')
    },
    function()
    {
        $('.icon22').attr('src','assets/img/icons/icon-46.svg')
    }
    )
}
)

$(function(){
    $('.icon23').hover(function()
    {
        $('.icon23').attr('src','assets/img/icons/cambio-71.svg')
    },
    function()
    {
        $('.icon23').attr('src','assets/img/icons/icon-47.svg')
    }
    )
}
)

$(function(){
    $('.icon24').hover(function()
    {
        $('.icon24').attr('src','assets/img/icons/cambio-72.svg')
    },
    function()
    {
        $('.icon24').attr('src','assets/img/icons/icon-48.svg')
    }
    )
}
)



